@import "../../shared/styles/media-queries";

.viewTypeSelect {
  text-transform: capitalize;

  @include for-mobile() {
    width: 100%;
    margin-top: 16px;
  }

  & :global(.MuiSelect-select) {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}

.viewTypeSelectItem {
  text-transform: capitalize;
}

.viewTypeSelectWeekView {
  @include for-mobile() {
    margin-top: 40px;
  }
}