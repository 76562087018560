.MuiButtonBase-root.submit-button {
  width: 146px;
  height: 45px;
  background: #5F8FE8;
  color: white;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 30px;
  &:hover{
    background-color: #5F8FE8;
  }
}

.MuiButtonBase-root.submit-button.disabled {
  background: lightgray;
  color: white;
  &:hover{
    background-color: lightgray;
  }
}