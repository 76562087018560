@mixin scroll($color: '#ddd', $inactiveColor: transparent, $width: 10px) {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    -webkit-border-radius: $width;
    -moz-border-radius: $width;
    border-radius: $width;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: $inactiveColor;
    margin-right: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color;
    -webkit-border-radius: $width;
    -moz-border-radius: $width;
    border-radius: $width;
  }
}