/************Main Colors************/
$Main--Primary: #5c8de8;
$Main--Secondary: #ff9900;
$Main--SecondaryLight: #ffcd00;
$Main--Third: #e6e9ec;
$Main--ThirdLight: #eef6fc;
$Main--Success: #5ce872;
$Main--Error: '';
$Main--Warning: #ee875b;
$Main--Default: #ffffff;
/************End Main Colors************/

/************Text Colors************/
$Text--Primary: #212121;
$Text--PrimaryLight: #3a3d3d;
$Text--Secondary: #386fd4;
$Text--SecondaryLight: #4176d9;
$Text--SecondaryLighten: #5c8de8;
$Text--Third: #394d69;
$Main--ThirdLight: #7387ac;
$Text--Error: #ff0000;
$Text--ErrorLight: #ff6f3e;
$Text--Default: #000000;
$Text--DefaultLight: #090909;
$Text--White: #ffffff;
$Text--Gray: #a5b0c0;
/************End Text Colors************/

/************Box Shadow Colors************/
$BoxShadow--Primary: 0px 4px 15px rgba(0, 0, 0, 0.25); // Login popup
$BoxShadow--Secondary: 0px 4px 7px rgba(100, 124, 142, 0.18); // Cards
/************END Box Shadow Colors************/

/************Background Colors************/
$Background--Default: #ffffff;
$Background--Primary: #f8f8f8;
/************Background Colors************/
