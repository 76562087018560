@mixin for-desktop {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
@mixin for-tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin for-mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}