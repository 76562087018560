.reset-popup {
  padding: 30px;

  .icon-component {
    display: flex;
    justify-content: center;
  }

  &__title {
    font-family: 'Open Sans', arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-top: 50px;
    margin-bottom: 10px;
    text-align: center;
  }

  &__text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;
    text-align: center;
  }

  &__form {
    &__input {
      margin-bottom: 30px;
      &_custom {
        .inner-content {
          padding: 6px 0 10px 10px;
          border-right: none;
        }
        .input_button {
          width: 123px;
          display: flex;
          align-items: center;
          margin-top: 4px;
          .btn_label {
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
    }
    &__btn {
      margin-top: 42px;
      margin-bottom: 30px;
    }
  }
}
