.pagination_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
  & > li {
    display: inline;
    &:first-child {
      margin-left: 0;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
    & > a {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height: 1.42857143;
      text-decoration: none;
      color: #337ab7;
      background-color: #fff;
      border: 1px solid #AFBCD5;
      border-radius: 4px;
      margin-left: -1px;
      margin-right: 4px;
    }
  }

  .disabled {
    display: none;
    opacity: 0;
    pointer-events: none;
  }
  .active {
    & > a {
      z-index: 3;
      color: #fff;
      background-color: #337ab7;
      border-color: #337ab7;
      cursor: default;
    }
  }
}
