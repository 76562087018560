* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

@font-face {
  font-family: 'Magallanes';
  src: url("/assets/fonts/MagallanesBold.otf") format("opentype");
}
@font-face {
  font-family: "Open Sans";
  src: url('/assets/fonts/OpenSans-Regular.ttf') format('opentype')
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Josefin Sans Regular'), local('JosefinSans-Regular'),
  url('/assets/fonts/Josefine Sans/josefin-sans-v14-latin-regular.woff2') format('woff2'),
  url('/assets/fonts/Josefine Sans/josefin-sans-v14-latin-regular.woff') format('woff'),
  url('/assets/fonts/Josefine Sans/josefin-sans-v14-latin-regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Josefin Sans SemiBold'), local('JosefinSans-SemiBold'),
  url('/assets/fonts/Josefine Sans/josefin-sans-v14-latin-600.woff2') format('woff2'),
  url('/assets/fonts/Josefine Sans/josefin-sans-v14-latin-600.woff') format('woff'),
  url('/assets/fonts/Josefine Sans/josefin-sans-v14-latin-600.ttf') format('truetype');
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Inter', 'Open Sans', arial, sans-serif;
}

input {
  border: none;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

input,
select,
textarea,
button {
  font-weight: 400;
  box-sizing: border-box;
  color: #000;
}

button,
input[type='button'] {
  cursor: pointer;
}

.pac-container {
  z-index: 1500 !important;
}
